let serverTimerInterval: NodeJS.Timeout = undefined;

function serverTimeUpdater() {
    let time = $('.server-time');
    let loadIntime = time.data('loadtime');
    let d = new Date(loadIntime);
    d.setSeconds(d.getSeconds() + 1);
    time.text(d.toLocaleDateString() + ' ' + d.toLocaleTimeString());
    time.data('loadtime', d);
}

let time = $('.server-time');
window.clearTimeout(serverTimerInterval);
if (time) {
    serverTimerInterval = setInterval(serverTimeUpdater, 1000);
}
