export class Api {
    constructor(private apiRoot: string) {
        if (this.apiRoot.endsWith('/')) {
            this.apiRoot = this.apiRoot.substr(0, this.apiRoot.length - 1);
        }
    }

    public async getJson<TModel>(path: string, data?: any): Promise<TModel> {
        if (!path || !path.length) {
            path = '/';
        }
        if (!path.startsWith('/')) {
            path = `/${path}`;
        }
        const tokens: NodeListOf<HTMLInputElement> = document.getElementsByName('__RequestVerificationToken') as any;
        const token = tokens && tokens.length ? tokens[0].value : '';
        const res = await fetch(`${this.apiRoot}${path}`, {            
            method: "POST",
            credentials: "same-origin",
            
            headers: {
                'Content-Type': 'application/json',
                'Accepts': 'application/json',      
                '__RequestVerificationToken': token,

            },
            body: data ? JSON.stringify(data) : undefined,
        });

        if (res.ok) {
            const data = await res.json() as TModel;
            return data;
        } else if (res.status === 403) {
            window.location.href = res.headers.get('A4LLoginLocation');
            return new Promise((res, rej) => {}); //Wait for page load
        } else {
            throw res;
        }
    }

    public async sendVerificationEmail() {
        const response = await this.getJson<{ Success: boolean }>(`/sendverificationemail`);        
        return response.Success;
    }

    public async checkVerificationEmail() {
        const response = await this.getJson<{ EmailVerified: boolean }>(`/checkverificationemail`);        
        return response.EmailVerified;
    }
}

export const applicationApi = new Api((window as any).APPLICATION_API);
