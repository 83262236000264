import * as ReactDOM from "react-dom";
import toast, { Toaster } from 'react-hot-toast';
import { createRoot } from 'react-dom/client'

(window as any).lbr = {
    toast
};

try {
    const root = createRoot(document.getElementById("react-notification-area")).render
    (
        <>
            <Toaster position='top-right' toastOptions={{
                duration: 2000,
                success: {
                    style: {
                    background: '#d4edda',
                    color: '#155724'
                    },
                },
                error: {
                    style: {
                        background: '#f8d7da',
                        color: '#721c24'
                    },
                    },
            }} />
        </>,
    );
} catch (e) {
    console.error(e);
}