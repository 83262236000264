import 'react';
import 'bootstrap';
import 'jquery';
import 'bootstrap-select';

import './fonts/Inter-Regular.ttf';
import './jquery';
import './notifications';
import './maintanance';
import './emailVerification';
import './mfaLoginSetup';


import "@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf";
import "@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff";
import "@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2";

(window as any).$("[data-toggle=popover]").popover();
    